import React, { useContext, useEffect, useState } from 'react'
import SideMenu from '../components/sidemenu'
import { useNavigate } from 'react-router-dom';
import { Service } from '../services/api';
import { AuthContext } from '../context/auth_context';
import Renderif from '../components/renderif';
import { AiFillCloseCircle } from 'react-icons/ai'

export default function HomeView() {
    const navigate = useNavigate();

    const { user, logout } = useContext(AuthContext)

    const [state, setState] = useState({
        planes: [],
        description_modal: false,
        plan: [],
        loading: false,
        planes_modal: false
    })

    useEffect(() => {


        init();

        return () => { }
    }, [])

    const init = async () => {
        setState({ ...state, loading: true })
        let _planes = await Service('GET', 'plans');

        if (_planes.status === 200) {
            setState({ ...state, planes: _planes.data.filter((plan) => plan.specialty === "NUTRITION") })
        } else if (_planes.status === 401) {
            logout();
            navigate('/')
        }
        console.log(_planes.data.filter((plan) => plan.specialty === "NUTRITION"))
    }

    const checkPlan = (plan) => {
        console.log(plan)

        let some = false;
        if (user?.subscriptions.length === 0) {
            return false
        }

        let _user_subs = []

        user?.subscriptions.map((sub) => {
            _user_subs.push(sub.name)
        })

        let _plan_subs = []

        plan.stripeSubscriptions.map((sub) => {
            _plan_subs.push(sub.stripeSubscription.name)
        })

        _user_subs.map((user_sub) => {
            if (_plan_subs.includes(user_sub)) {
                some = true;
            }
        })






        if (some) {
            return true;
        } else {
            return false
        }
    }

    return (
        <div className='container-fluid d-flex h-100'>

            <div>
                <SideMenu page={'home'}></SideMenu>
            </div>
            <Renderif isTrue={state.planes_modal}>
                <div className='overlay d-flex justify-content-center align-items-center'>
                    <div className='bv-modal shadow'>
                        <div className='px-4 pt-3 d-flex align-items-center'>
                            <div className=' f-16 px-4 pt-3 text-gray bolder'> </div>
                            <div className='col'></div>
                            <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                                setState({ ...state, planes_modal: false })

                            }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
                        </div>
                        <div className='px-4 pb-5'>

                            <div className='px-sm-4 pt-3 d-flex-center'>
                                <div className="d-flex overflow-auto">
                                    <div className="plan-container bg-gray-card me-3">
                                        <div className="image-container">
                                            <img src="/assets/images/plan_funcional.png" className="img-plan"></img>
                                        </div>
                                        <div className="plan-description">
                                            <div className='f-26 bold'>PLAN FUNCIONAL</div>
                                            <div className='f-16 text-gray'>
                                                Al seleccionar el plan, tendrás acceso a Tips, Videos y Documentos en Aprende Más. Recetas
                                                Personalizadas al plan que decidiste comprar. Chat directo con Especialistas en Nutricion,
                                                Ginecologia y Psicologia.</div>
                                        </div>
                                        <div className="plan-price">
                                            <div className='f-28 bold'>MXN 99</div><div className='por-mes text-gray'><div className='mes'>por</div><div>mes</div></div>
                                        </div>
                                        <div className="sus-container">
                                            <div className="suscription-button cursor-pointer" onClick={() => {
                                                // onPurchase("price_1PRIGgBaFeuuZx1I7Xa3wJM2", "PLAN FUNCIONAL")
                                                // navigate('/user/agendar/1')
                                            }}>Suscribirse</div>
                                        </div>
                                        <div className="desc-container f-16 text-gray">
                                            <p>Esto incluye:</p>
                                            <ul>
                                                <li>
                                                    Acceso a recetas del plan seleccionado.</li>
                                                <li>Tips personalizados.
                                                </li>
                                                <li>Documentos relevantes.</li>
                                                <li>
                                                    Videos informativos.</li>
                                                <li>Chat con Especialistas de tu elección.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="plan-container bg-gray-card">
                                        <div className="image-container">
                                            <img src="/assets/images/plan_funcional_plus.png" className="img-plan"></img>
                                        </div>
                                        <div className="plan-description">
                                            <div className='f-26 bold'>PLAN FUNCIONAL PLUS</div>
                                            <div className='f-16 text-gray'>
                                                El Plan Funcional Plus te ofrece acceso a recetas, tips, documentos y videos del plan seleccionado. Además, incluye una cita con cualquier especialista, sin restricciones de especialidad. ¡Aprovecha este plan integral para tu salud!</div>
                                        </div>
                                        <div className="plan-price">
                                            <div className='f-28 bold'>MXN 790</div><div className='por-mes text-gray'><div className='mes'>por</div><div>mes</div></div>
                                        </div>
                                        <div className="sus-container">
                                            <div className="suscription-button cursor-pointer" onClick={() => {
                                                // onPurchase("price_1PRHnZBaFeuuZx1IDDtgACuM", "PLAN FUNCIONAL PLUS")
                                                // navigate('/user/agendar/2')
                                            }}>Suscribirse</div>
                                        </div>
                                        <div className="desc-container f-16 text-gray">
                                            <p>Esto incluye:</p>
                                            <ul>
                                                <li>
                                                    Acceso a recetas del plan seleccionado.
                                                </li>
                                                <li>Tips personalizados.
                                                </li>
                                                <li>Documentos relevantes.</li>
                                                <li>
                                                    Videos informativos.</li>
                                                <li>1 cita con cualquier especialista (sin restricciones de especialidad).</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </Renderif>

            <Renderif isTrue={state.description_modal}>
                <div className='overlay d-flex justify-content-center align-items-center'>
                    <div className='bv-modal shadow'>
                        <div className='px-4 pt-3 d-flex align-items-center'>
                            <div className='f-16 text-gray'> </div>
                            <div className='col'></div>
                            <div className='bv-icon bv-icon--mid cursor-pointer' onClick={() => {

                                setState({ ...state, description_modal: false })

                            }}> <AiFillCloseCircle size={24} color="#00BF6B"></AiFillCloseCircle></div>
                        </div>
                        <div className='px-4 pb-5'>
                            <div className='plan-card-desc' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${state.plan?.image?.name})` }}></div>
                            <div className='px-4 pt-3 d-flex align-items-center'>
                                <div className='f-16 text-green bolder p-1'>{state.plan?.title}</div>
                                <div className='col'></div>
                                <div className={`bv-cta bv-cta--success`} onClick={() => {
                                    navigate('/user/precios')
                                }}>
                                    Ir al Plan

                                </div>
                            </div>
                            <div className='mt-3  overflow-auto max-h-400'>
                                {state.plan?.descripcion}
                            </div>
                        </div>
                    </div>
                </div>
            </Renderif>

            <div className='col h-100 overflow-auto hide-scroll'>

                <div className='container-fluid bg-white sticky-top '>
                    <img className='m-3' width={120} src='../assets/images/logo.png'></img>
                </div>
                <div className='container-fluid container-xl mx-auto p-3'>
                    <div className='f-28 text-gray bold'>Explora Nuestros Planes Alimenticios</div>
                    <div className='f-16 text-gray'>Tips y recetas sencillas elaboradas por nutriólogas certificadas</div>
                </div>
                <div className='container-fluid container-xl mx-auto px-3'>
                    <div className='row'>
                        {
                            state.planes.map((item, i) => {
                                return (
                                    <div key={i} className='col-12  col-xl-4 pe-4 pb-3'>
                                        <div className='plan-card-square' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${item?.image?.name})` }} onClick={() => {


                                            if (checkPlan(item)) {
                                                navigate('/user/plan', { state: { item } })
                                            } else {
                                                setState({ ...state, description_modal: true, plan: item })
                                            }

                                        }}>
                                            <div className='layout-cover'></div>
                                        </div>
                                        <div className='p-3'>
                                            <div className='d-flex-center'>
                                                <div className='f-18  bolder'>{item.title}</div>
                                                <div className='col'></div>
                                                <div className='bv-cta bv-cta--success' onClick={() => {
                                                    // navigate('/user/agendar')
                                                    setState({ ...state, planes_modal: true })
                                                }}>
                                                    Comprar Plan

                                                </div>
                                            </div>
                                            <div className='f-10 mt-2'>{item.descripcion}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {/* ANTES */}
                {/* <div className='container-fluid container-xl mx-auto p-3'>
                    <div className='f-28 text-gray bold'>PLANES ALIMENTICIOS</div>
                    <div className='f-16 text-gray'>Tips y recetas sencillas elaboradas por nutriólogas certificadas</div>
                </div>

                <div className='container-fluid container-xl mx-auto px-3'>
                    <div className='row'>
                        {
                            state.planes.filter((plan) => plan.title === "Test My Diet").map((item, i) => {
                                return (
                                    <div key={i} className='col-12  col-xl-4 col-xxl-3 pe-2 pb-3'>
                                        <div className='plan-card' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${item?.image?.name})` }} onClick={() => {


                                            if (checkPlan(item)) {
                                                navigate('/user/plan', { state: { item } })
                                            } else {
                                                setState({ ...state, description_modal: true, plan: item })
                                            }

                                        }}>
                                            <div className='layout-cover'></div>
                                            <div className='col'></div>
                                            <div className='p-3'>
                                                <div className='f-18 text-white bolder'>{item.title}</div>
                                                <div className='f-14 text-white'>{item.type}</div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='container-fluid container-xl mx-auto p-3'>
                    <div className='f-28 text-gray bold'>PLANES ESPECÍFICOS PARA CONDICIONES DE SALUD</div>
                </div>

                <div className='container-fluid container-xl mx-auto px-3'>
                    <div className='row'>
                        {
                            state.planes.filter((plan) => plan.title !== "Test My Diet" && plan.title !== "Salud 360").map((item, i) => {
                                return (
                                    <div key={i} className='col-12  col-xl-4 col-xxl-3 pe-2 pb-3'>
                                        <div className='plan-card' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${item?.image?.name})` }} onClick={() => {


                                            if (checkPlan(item)) {
                                                navigate('/user/plan', { state: { item } })
                                            } else {
                                                setState({ ...state, description_modal: true, plan: item })
                                            }

                                        }}>
                                            <div className='layout-cover'></div>
                                            <div className='col'></div>
                                            <div className='p-3'>
                                                <div className='f-18 text-white bolder'>{item.title}</div>
                                                <div className='f-14 text-white'>{item.type}</div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='container-fluid container-xl mx-auto p-3'>
                    <div className='f-28 text-gray bold'>PLANES GENERALES DE SALUD</div>
                </div>

                <div className='container-fluid container-xl mx-auto px-3'>
                    <div className='row'>
                        {
                            state.planes.filter((plan) => plan.title === "Salud 360").map((item, i) => {
                                return (
                                    <div key={i} className='col-12  col-xl-4 col-xxl-3 pe-2 pb-3'>
                                        <div className='plan-card' style={{ backgroundImage: ` url(https://mydiet-assets-2023.s3.amazonaws.com/${item?.image?.name})` }} onClick={() => {


                                            if (checkPlan(item)) {
                                                navigate('/user/plan', { state: { item } })

                                            } else {
                                                setState({ ...state, description_modal: true, plan: item })
                                            }

                                        }}>
                                            <div className='layout-cover'></div>
                                            <div className='col'></div>
                                            <div className='p-3'>
                                                <div className='f-18 text-white bolder'>{item.title}</div>
                                                <div className='f-14 text-white'>{item.type}</div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div> */}
                {/* ANTES */}
            </div>

        </div>
    )
}
